import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const VerschoenerungDerAugen = ({ data, location, ...props }) => {
    return (
        <Layout
            location={location}
            title="Augenlidverbesserung / Schlupflider / Tränensäcke"
            noSlider
            {...props}
        >
            <p>
                Die Haut der Oberlider und der Unterlider zeigen im Laufe der
                Lebensjahre Erscheinungen der Hauterschöpfung.
            </p>
            <p>
                Neben allgemeineren Veränderungen mit schlaffer wirkender Haut
                zeigt sich an den Oberlidern mehr oder weniger stark hängende
                Haut, die sogenannten Schlupflider.
            </p>
            <p>
                Diese lassen oft einen müden, erschöpften Ausdruck auf das
                Gegenüber wirken, auch wenn es so nicht ist.
            </p>
            <p>
                An den Unterlidern zeigt die Haut diesen Ausdruck durch die
                sogenannten Tränensäcke.
            </p>
            <p>
                Beides kann wesentlich verbessert werden durch verschiedene
                Behandlungsformen:
                <ul>
                    <li>
                        Laser oder Operation der Schlupflider oder Unterlider
                    </li>
                    <li>Fadenlifting</li>
                    <li>Hyaluronsäure Injektionen</li>
                    <li>Chemisches Peeling</li>
                    <li>
                        Mikroneedeling mit oder ohne chemisches Peeling oder
                        Plasma
                    </li>
                    <li>Radiofrequenztherapie (Pelleve`), früher Plasmage</li>
                    <li>Plateled Rich Plasma (PRP) Injektionen</li>
                </ul>
            </p>
            <p>
                Wir erklären Ihnen an Ihren Augen die Möglichkeiten der
                verschiedenen Techniken und den daraus resultierenden
                Verbesserungen.
            </p>

            <Questions title="Augenlidverbesserung / Schlupflider / Tränensäcke" />
        </Layout>
    );
};

export default VerschoenerungDerAugen;
